import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Interpolation, Theme } from '@emotion/react';
import { motion } from 'framer-motion';
import { StructuredTextDocument } from 'react-datocms';
import Container from '../primitives/grid/container';
import Color, { colors } from '../primitives/colors';
import LanguageSwitcher from '../language/language-switcher';
import ColSet from '../primitives/grid/col-set';
import {
    DropdownInsert,
    DropdownTrigger,
    MegaMenu,
    MegaMenuDropdown,
    MenuItem,
    MenuList,
    SubMenu,
} from '../primitives/mega-menu';
import { useLanguage } from '../language/language-provider';
import Logo from '../primitives/logo/logo.svg';
import { StyledButtonAsLink } from '../primitives/styled-button';
import Link, { getLink } from '../primitives/link';

import { borderRadius } from '../primitives/tokens';
import FadeTransition from '../animations/transitions/fade-transition';
import Typography, { smallTextStyles } from '../primitives/typography';
import hierarchicalMenu, { HierarchicalMenuItem, ParsedMenuItem } from '../../util/hierarchical-menu';
import MediaItem from '../primitives/media-item';
import AspectRatio from '../primitives/aspect-ratio';
import RightCaret from './menu-caret-right.svg';
import CaretDown from '../primitives/utils/shared-svgs/caret-down.svg';
import DatoAsset from '../primitives/asset';
import CustomStructuredTextRenderer from '../structured-text/custom-structured-text-renderer';
import BasicSlider, { useSliderContext } from '../basic-slider';
import useDatoRouter from '../language/use-dato-router';

const linkStyles = {
    transition: '0.2s',
    padding: '4px 6px',
    borderRadius: borderRadius.small,
    '&:hover': {
        backgroundColor: '#fff1',
    },
} as Interpolation<Theme>;

interface FramerIndicatorProps {
    slideIndex: number
    idx: number
    slideShowing: boolean
}

const FramerIndicator = ({ slideIndex, idx, slideShowing }: FramerIndicatorProps): JSX.Element|null => {
    if (slideIndex === idx) {
        return (
            <motion.div
                layoutId="header-indicator"
                css={{
                    borderTop: '1px solid var(--header-color-1)',
                    width: '100%',
                    position: 'absolute',
                    top: '52px',
                }}
                style={{
                    borderRadius: borderRadius.medium,
                    opacity: slideShowing ? 1 : 0,
                }}
                transition={{
                    type: 'spring',
                    duration: 0.6,
                }}
            />
        );
    }
    return null;
};

const BenefitInsert = ({ items }: { items: ParsedMenuItem[] }): JSX.Element => (
    <div css={{
        paddingRight: '32px',
        borderRight: `1px solid ${colors.grey50}`,
        display: 'flex',
        alignItems: 'center',
    }}
    >
        <BasicSlider.Slides animation="fade">
            {items.map(item => (
                <div css={{
                    display: 'flex',
                    gap: '32px',
                }}
                >
                    <AspectRatio
                        breakpoints={{
                            dt: { width: 147, height: 169 },
                        }}
                        css={{
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '140px',
                        }}
                    >
                        <DatoAsset
                            css={{
                                height: '100%',
                                width: '100%',
                                borderRadius: borderRadius.medium,
                                overflow: 'hidden',
                                transform: 'translateZ(0px)',
                            }}
                            noAnimate
                            asset={item?.benefitQuote?.[0]?.photo}
                        />
                    </AspectRatio>
                    <div css={{
                        maxWidth: '360px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                    >
                        <Typography
                            fontSize={{
                                dt: 1822,
                                tb: 1627,
                                mb: 1226,
                            }}
                            fontWeight="light"
                            override
                        >
                            <div>“</div>
                            <CustomStructuredTextRenderer
                                data={item?.benefitQuote?.[0]?.quote as StructuredTextDocument}
                            />
                        </Typography>

                        <Typography
                            css={{
                                paddingTop: '8px',
                            }}
                            fontSize={{
                                dt: 1226,
                            }}
                            override
                        >
                            <span>{item?.benefitQuote?.[0]?.attribution}</span>
                            <span css={{ marginLeft: '12px', opacity: 0.5 }}>{item?.benefitQuote?.[0]?.role}</span>
                        </Typography>
                    </div>
                </div>
            ))}
        </BasicSlider.Slides>
    </div>
);

const BenefitChildLink = (innerItem: ParsedMenuItem & { idx: number }): JSX.Element => {
    const { jumpTo } = useSliderContext();

    const { text, title, idx, link } = innerItem;

    return (
        <Link
            onMouseOver={() => {
                jumpTo(idx);
            }}
            css={{ width: '100%', height: '100%' }}
            to={getLink(link)}
        >
            <MenuItem
                css={{
                    padding: '12px',
                    borderRadius: borderRadius.small,
                    transition: '0.3s',
                    height: '100%',
                    '&:hover': {
                        backgroundColor: colors.grey50,
                    },
                }}
            >
                <Typography
                    fontSize={{
                        dt: 1322,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    fontSize={{
                        dt: 1322,
                    }}
                    css={{
                        opacity: 0.5,
                        textAlign: 'justify',
                    }}
                >
                    {text as string}
                </Typography>
            </MenuItem>
        </Link>
    );
};

interface LittleMenuProps {
    slideIndex: number
    idx: number
    slideShowing: boolean
    setSlideIndex: (index: number) => void
    setSlideShowing: (state: boolean) => void
    item: ParsedMenuItem
}
const LittleMenu = ({
    item, idx, slideIndex, slideShowing, setSlideIndex, setSlideShowing,
}: LittleMenuProps): JSX.Element => (
    <BasicSlider>
        <MenuItem
            css={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
            onMouseEnter={() => {
                setSlideIndex(idx);
                setSlideShowing(true);
            }}
        >
            <div css={{
                position: 'relative',
            }}
            >
                {item.link?.length > 0 ? (
                    <Link to={getLink(item.link)}>
                        <Typography
                            as={DropdownTrigger}
                            fontSize={{
                                dt: 1420,
                            }}
                            css={{
                                padding: '24px 4px',
                            }}
                        >
                            {item.title}
                            {' '}
                            <CaretDown css={{
                                marginLeft: '8px',
                            }}
                            />
                        </Typography>
                    </Link>
                ) : (
                    <Typography
                        as={DropdownTrigger}
                        fontSize={{
                            dt: 1420,
                        }}
                        css={{
                            padding: '24px 4px',
                        }}
                    >
                        {item.title}
                        {' '}
                        <CaretDown css={{
                            marginLeft: '8px',
                        }}
                        />
                    </Typography>
                )}
                <FramerIndicator idx={idx} slideIndex={slideIndex} slideShowing={slideShowing} />
            </div>
            <DropdownInsert css={{
                minWidth: item.showBenefitsOnSide ? '840px' : '236px',
                maxWidth: item.showBenefitsOnSide ? '960px' : '436px',
                color: colors.primaryDarkPurple,
                '@media (max-width: 1400px)': {
                    transform: 'translateX(20%)',
                },
            }}
            >
                <SubMenu css={{
                    display: item.showBenefitsOnSide ? 'flex' : 'block',
                    padding: item.showBenefitsOnSide ? '32px' : '14px',
                }}
                >
                    {item.showBenefitsOnSide && (
                        <BenefitInsert items={item.childMenu} />
                    )}
                    <MenuList css={{
                        flexDirection: 'column',
                        width: '100%',
                        display: 'grid',
                        gridTemplateRows: `repeat(${item.childMenu.length}, 1fr)`,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        paddingLeft: item.showBenefitsOnSide ? '32px' : '0',
                    }}
                    >
                        {item.childMenu.map((innerItem, idx) => (
                            <BenefitChildLink {...innerItem} idx={idx} />
                        ))}
                    </MenuList>
                </SubMenu>
            </DropdownInsert>
        </MenuItem>
    </BasicSlider>
);

const DesktopHeader = ({ overApex }: { overApex?: boolean }): JSX.Element => {
    const { language } = useLanguage();

    const staticData = useStaticQuery<Queries.DesktopHeaderDataQuery>(graphql`
        query DesktopHeaderData {
            auBenefits: datoCmsBenefitsGrid(locale: "en-AU") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
            ukBenefits: datoCmsBenefitsGrid(locale: "en-GB") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
            usBenefits: datoCmsBenefitsGrid(locale: "en-US") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
            auSupplemental: datoCmsHeaderSupplemental(locale: "en-AU") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            },
            ukSupplemental: datoCmsHeaderSupplemental(locale: "en-GB") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            }
            usSupplemental: datoCmsHeaderSupplemental(locale: "en-GB") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            }
            au: allDatoCmsHeaderMenuItem(locale: "en-AU") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    text
                    position
                    showBenefitsOnSide
                    benefitQuote {
                        quote {
                            blocks
                            links
                            value
                        }
                        role
                        attribution
                        photo {
                            ...DatoCmsInternalAsset
                        }
                    }
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                    mediaItem {
                        ...MediaItemProps
                    }
                }
            }
            uk: allDatoCmsHeaderMenuItem(locale: "en-GB") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    text
                    position
                    showBenefitsOnSide
                    benefitQuote {
                        quote {
                            blocks
                            links
                            value
                        }
                        role
                        attribution
                        photo {
                            ...DatoCmsInternalAsset
                        }
                    }
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                    mediaItem {
                        ...MediaItemProps
                    }
                }
            }
            us: allDatoCmsHeaderMenuItem(locale: "en-US") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    text
                    position
                    showBenefitsOnSide
                    benefitQuote {
                        quote {
                            blocks
                            links
                            value
                        }
                        role
                        attribution
                        photo {
                            ...DatoCmsInternalAsset
                        }
                    }
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                    mediaItem {
                        ...MediaItemProps
                    }
                }
            }
        }
    `);

    const localSupplementalData = staticData[`${(language as 'au' | 'uk' | 'us')}Supplemental`];
    const menuForLang = staticData[(language as 'au' | 'uk' | 'us')].nodes;
    const data = hierarchicalMenu(menuForLang as unknown as HierarchicalMenuItem[]);

    const [slideIndex, setSlideIndex] = React.useState<number>(0);
    const [slideShowing, setSlideShowing] = React.useState<boolean>(false);

    const getPath = useDatoRouter();

    return (
        <div css={{
            width: '100%',
            position: 'relative',
        }}
        >
            <Color
                css={{
                    height: '38px',
                // This is intrinsic, but just to ensure on all devices for the Sticky effect
                }}
                color="shadesWhite"
                backgroundColor="primaryDarkPurple"
            >
                <FadeTransition shouldChange={language}>
                    <Container css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                    >
                        <LanguageSwitcher />
                        {localSupplementalData && (
                            <div>
                                <ColSet
                                    breakpoints={{
                                        dt: { between: 16 },
                                    }}
                                    css={{
                                        div: {
                                            padding: '11px 0',
                                        },
                                        'div, a': {
                                            position: 'relative',
                                            ...smallTextStyles,
                                        },
                                        'div:not(:last-of-type)::after': {
                                            display: 'block',
                                            content: '""',
                                            position: 'absolute',
                                            height: '17px',
                                            width: '1px',
                                            backgroundColor: colors.shadesWhite,
                                            opacity: 0.3,
                                            top: '9px',
                                            right: '-8px',
                                        },
                                    } as Interpolation<Theme>}
                                >
                                    <div>
                                        <a
                                            css={linkStyles}
                                            href={`mailto:${localSupplementalData.emailAddress}`}
                                        >
                                            {localSupplementalData.emailAddress}
                                        </a>
                                    </div>
                                    <div>
                                        <GatsbyLink css={linkStyles} to={getPath('/sign-in', language)}>
                                            Login
                                        </GatsbyLink>
                                    </div>
                                </ColSet>
                            </div>
                        )}
                    </Container>
                </FadeTransition>
            </Color>
            <div>
                <Container>
                    <div css={{
                        position: 'relative',
                    }}
                    >
                        <Link
                            to="/"
                            css={{
                                padding: '24px 0',
                                position: 'absolute',
                                left: '0',
                                color: colors.primaryOrange,
                                zIndex: 2,
                            }}
                            className="logo"
                        >
                            <Logo />
                        </Link>
                        {localSupplementalData?.headerGetStartedLink
                            && localSupplementalData.headerGetStartedLink.map(link => (
                                <div css={{
                                    position: 'absolute',
                                    right: '0',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    zIndex: 2,
                                }}
                                >
                                    <StyledButtonAsLink
                                        variant="header"
                                        to={getLink(link?.link)}
                                        style={{
                                            opacity: overApex ? 0 : 1,
                                            pointerEvents: overApex ? 'none' : 'all',
                                        }}
                                    >
                                        {link?.title}
                                    </StyledButtonAsLink>
                                    <StyledButtonAsLink
                                        variant="primary"
                                        to={getLink(link?.link)}
                                        style={{
                                            opacity: overApex ? 1 : 0,
                                            pointerEvents: overApex ? 'all' : 'none',
                                            position: 'absolute',
                                        }}
                                    >
                                        {link?.title}
                                    </StyledButtonAsLink>
                                </div>
                            ))}
                        <div onMouseLeave={() => {
                            setSlideShowing(false);
                        }}
                        >
                            <MegaMenu>
                                <MegaMenuDropdown>
                                    <MenuList css={{
                                        gap: '26px',
                                        width: 'fit-content',
                                        margin: 'auto',
                                    }}
                                    >
                                        {data.childMenu.map((item, idx) => {
                                            if (item.childMenu && item.childMenu.length > 0) {
                                                if (item.childMenu[0]
                                                    && item.childMenu[0].childMenu
                                                    && item.childMenu[0].childMenu[0]
                                                ) {
                                                    // Big menu
                                                    return (
                                                        <MenuItem
                                                            css={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                width: '100%',
                                                            }}
                                                            onMouseEnter={() => {
                                                                setSlideIndex(idx);
                                                                setSlideShowing(true);
                                                            }}
                                                        >
                                                            <div css={{
                                                                position: 'relative',
                                                            }}
                                                            >
                                                                {item.link?.length > 0 ? (
                                                                    <Link to={getLink(item.link)}>
                                                                        <Typography
                                                                            as={DropdownTrigger}
                                                                            fontSize={{
                                                                                dt: 1420,
                                                                            }}
                                                                            css={{
                                                                                padding: '24px 4px',
                                                                            }}
                                                                        >
                                                                            {item.title}
                                                                            {' '}
                                                                            <CaretDown css={{
                                                                                marginLeft: '8px',
                                                                            }}
                                                                            />
                                                                        </Typography>
                                                                    </Link>
                                                                ) : (
                                                                    <Typography
                                                                        as={DropdownTrigger}
                                                                        fontSize={{
                                                                            dt: 1420,
                                                                        }}
                                                                        css={{
                                                                            padding: '24px 4px',
                                                                        }}
                                                                    >
                                                                        {item.title}
                                                                        {' '}
                                                                        <CaretDown css={{
                                                                            marginLeft: '8px',
                                                                        }}
                                                                        />
                                                                    </Typography>
                                                                )}
                                                                <FramerIndicator idx={idx} slideIndex={slideIndex} slideShowing={slideShowing} />
                                                            </div>
                                                            <DropdownInsert css={{
                                                                minWidth: '80%',
                                                                left: '10%',
                                                                color: colors.primaryDarkPurple,
                                                            }}
                                                            >
                                                                <SubMenu>
                                                                    <MenuList css={{
                                                                        flexDirection: 'row',
                                                                        padding: '14px',
                                                                    }}
                                                                    >
                                                                        {item.childMenu.map(innerItem => (
                                                                            <div
                                                                                css={{
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <MenuItem
                                                                                    css={{
                                                                                        padding: '12px',
                                                                                        borderRadius: borderRadius.small,
                                                                                        transition: '0.3s',
                                                                                    }}
                                                                                >
                                                                                    <AspectRatio
                                                                                        breakpoints={{
                                                                                            dt: { width: 228, height: 122 },
                                                                                        }}
                                                                                        css={{
                                                                                            marginBottom: '20px',
                                                                                        }}
                                                                                    >
                                                                                        <MediaItem
                                                                                            css={{
                                                                                                height: '100%',
                                                                                                width: '100%',
                                                                                                borderRadius: borderRadius.medium,
                                                                                                overflow: 'hidden',
                                                                                                transform: 'translateZ(0px)',
                                                                                            }}
                                                                                            noAnimate
                                                                                            item={innerItem.mediaItem}
                                                                                        />
                                                                                    </AspectRatio>
                                                                                    <Typography
                                                                                        color="primaryOrange"
                                                                                        fontSize={{
                                                                                            dt: 1822,
                                                                                        }}
                                                                                        css={{
                                                                                            paddingBottom: '16px',
                                                                                        }}
                                                                                    >
                                                                                        {innerItem.title}
                                                                                    </Typography>
                                                                                    {innerItem.childMenu
                                                                                        && innerItem.childMenu.map((deepInnerItem, idx) => (
                                                                                            <Link
                                                                                                to={getLink(deepInnerItem.link)}
                                                                                                css={{
                                                                                                    display: 'flex',
                                                                                                    width: '100%',
                                                                                                    fontSize: '13px',
                                                                                                    borderBottom: (idx !== innerItem.childMenu.length - 1) ? `1px solid ${colors.grey100}` : '',
                                                                                                    padding: '10px 0',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    '&:hover div': {
                                                                                                        opacity: 1,
                                                                                                    },
                                                                                                    div: {
                                                                                                        opacity: 0,
                                                                                                        transition: '0.1s',
                                                                                                    },
                                                                                                    '&:hover': {
                                                                                                        color: colors.primaryPurple,
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                {deepInnerItem.title}
                                                                                                <div>
                                                                                                    <RightCaret />
                                                                                                </div>
                                                                                            </Link>
                                                                                        ))}
                                                                                </MenuItem>
                                                                            </div>
                                                                        ))}
                                                                    </MenuList>
                                                                </SubMenu>
                                                            </DropdownInsert>
                                                        </MenuItem>
                                                    );
                                                }

                                                return (
                                                    <LittleMenu
                                                        item={item}
                                                        idx={idx}
                                                        slideIndex={slideIndex}
                                                        slideShowing={slideShowing}
                                                        setSlideIndex={setSlideIndex}
                                                        setSlideShowing={setSlideShowing}
                                                    />
                                                );
                                            }
                                            // Actual Menu Item Top Level
                                            return (
                                                <MenuItem
                                                    css={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                    }}
                                                    onMouseEnter={() => {
                                                        setSlideIndex(idx);
                                                        setSlideShowing(true);
                                                    }}
                                                >
                                                    <div css={{
                                                        position: 'relative',
                                                    }}
                                                    >
                                                        <Typography
                                                            as={Link}
                                                            to={getLink(item.link)}
                                                            fontSize={{
                                                                dt: 1420,
                                                            }}
                                                            css={{
                                                                padding: '24px 4px',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                        <FramerIndicator idx={idx} slideIndex={slideIndex} slideShowing={slideShowing} />
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                </MegaMenuDropdown>
                            </MegaMenu>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default DesktopHeader;
